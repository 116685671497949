import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from '../environments/environment';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  private httpClient = inject(HttpClient);
  design: WritableSignal<Design | null> = signal(null);
  router: Router = inject(Router);

  constructor() {
    this.setDefaultDesign();
  }

  loadDesign(jobId: number) {
    if (jobId === undefined) {
      this.setDefaultDesign();
    }

    this.httpClient
      .get<Design>(`${environment.apiUrl}/leads/jobs/${jobId}/design`)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 404) {
              this.setDefaultDesign();
              this.router.navigate(['/']);
            }
          }
          throw error;
        })
      )
      .subscribe((design) => {
        this.set(design);
        this.setMetaTags();
      });
  }

  setMetaTags() {
    document.title = this.design()?.jobTitle ?? '';
    document
      .querySelector('meta[name="og:title"]')
      ?.setAttribute('content', this.design()?.jobTitle ?? '');
    document
      .querySelector('meta[name="og:description"]')
      ?.setAttribute('content', this.design()?.description ?? '');
    document
      .querySelector('meta[name="og:image"]')
      ?.setAttribute('content', this.design()?.imageURL ?? '');
    document
      .querySelector('meta[name="og:url"]')
      ?.setAttribute('content', window.location.href);
  }

  private setDefaultDesign() {
    this.set({
      companyName: 'Catch',
      accent: '#374678',
      background: '#6E8BEF',
      buttonFont: '#FFFFFF',
      logoURL: 'assets/img/catch_logo.png',
      iconURL: 'assets/img/catch_icon.png',
      jobTitle: 'Job Title',
      description: 'Description',
      imageURL: 'assets/img/catch_image.png',
      startDate: '1970-01-01',
      workLocations: ['Location'],
      employmentTypeId: 1,
    });
  }

  private set(design: Design) {
    this.design.set({
      ...design,
      description: design.description.replaceAll('\n', '<br>'),
    });

    document.documentElement.style.setProperty('--accent-1', design.accent);
    document.documentElement.style.setProperty('--accent-2', design.background);
    document.documentElement.style.setProperty(
      '--font-color',
      design.buttonFont
    );

    const favIcon = document.querySelector('link[rel="icon"]') ?? null;
    if (favIcon !== null) {
      favIcon.setAttribute('href', design.iconURL);
    }
  }
}

interface Design {
  companyName: string;
  jobTitle: string;
  description: string;
  imageURL: string;
  accent: string;
  background: string;
  buttonFont: string;
  logoURL: string;
  iconURL: string;
  startDate: string;
  workLocations: string[];
  employmentTypeId: number;
}

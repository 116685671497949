export const environment = {
  version: 'develop',
  buildDate: '202504011123',
  environment: 'develop',
  release: 'd77f9043c0bceb68e8c3f35c12bb108958445b89',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://develop.api.catch-talents.dev',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
